import * as prismicClient from '@prismicio/client';
import { cardsAdapter } from '~/slices/CardsList/adapter';
import {
  guideLinks,
  offerLinks,
  pageLinks,
  routerLinks,
} from '~/utils/fetch-links';
import { stripRichTextWrapperTag } from '~/utils/html';

const adapter = async (
  slice,
  { context, page, i18n, prismic, enhancedLinkSerializer },
) => {
  const taxonomies = context?.taxonomies.map((item) => item.taxonomy.id);
  const isPro = context.type === 'corporate';

  const guideCards = await getGuidesByTaxonomy({
    page,
    taxonomies,
    prismic,
    isPro,
    lang: i18n.localeProperties.value.language,
  });

  return {
    head: {
      title: stripRichTextWrapperTag(slice?.primary?.title),
    },
    items: {
      featured: true,
      withCarousel: false,
      totalPages: guideCards.totalPages,
      cards: cardsAdapter(guideCards.cards, i18n, enhancedLinkSerializer),
    },
  };
};

const getGuidesByTaxonomy = async ({
  taxonomies,
  prismic,
  isPro,
  lang,
  page,
}) => {
  const items = await prismic.get({
    filters: [
      prismicClient.filter.at('document.type', 'page_guide'),
      prismicClient.filter.any('my.page_guide.taxonomies.taxonomy', taxonomies),
      isPro
        ? prismicClient.filter.at('document.tags', ['pro'])
        : prismicClient.filter.not('document.tags', ['pro']),
    ],
    orderings: [
      {
        field: 'my.page_guide.date',
        direction: 'desc',
      },
      {
        field: 'document.last_publication_date',
        direction: 'desc',
      },
    ],
    page,
    fetchLinks: [...routerLinks, ...guideLinks, ...offerLinks, ...pageLinks],
    pageSize: 16,
    lang,
  });

  return {
    totalPages: items.total_pages,
    cards: items.results.map((result) => ({
      edito_carousel_card: { ...result, link_type: 'Document' },
    })),
  };
};

export default adapter;
